export const CLEAR_STATE = "CLEAR_STATE";
export const GET_RECIPES = "GET_RECIPES";
export const GET_RECIPE = "GET_RECIPE";
export const GET_DIETS = "GET_DIETS";
export const GET_BY_NAME = "GET_BY_NAME";
export const CREATE_RECIPE = "CREATE_RECIPE";
export const ORDER_BY_NAME = "ORDER_BY_NAME";
export const ORDER_BY_SCORE = "ORDER_BY_SCORE";
export const RECIPE_DETAIL = "RECIPE_DETAIL";
export const FILTER_BY_DIET = "FILTER_BY_DIET";
export const FILTER_BY_CREATOR = "FILTER_BY_CREATOR";
